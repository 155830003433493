/* Inter Fonts */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/InterThin.eot");
  src: url("../fonts/InterThin.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterThin.woff2") format("woff2"),
    url("../fonts/InterThin.woff") format("woff"),
    url("../fonts/InterThin.ttf") format("truetype"),
    url("../fonts/InterThin.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/InterExtraLight.eot");
  src: url("../fonts/InterExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterExtraLight.woff2") format("woff2"),
    url("../fonts/InterExtraLight.woff") format("woff"),
    url("../fonts/InterExtraLight.ttf") format("truetype"),
    url("../fonts/InterExtraLight.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/InterLight.eot");
  src: url("../fonts/InterLight.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterLight.woff2") format("woff2"),
    url("../fonts/InterLight.woff") format("woff"),
    url("../fonts/InterLight.ttf") format("truetype"),
    url("../fonts/InterLight.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/InterRegular.eot");
  src: url("../fonts/InterRegular.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterRegular.woff2") format("woff2"),
    url("../fonts/InterRegular.woff") format("woff"),
    url("../fonts/InterRegular.ttf") format("truetype"),
    url("../fonts/InterRegular.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/InterMedium.eot");
  src: url("../fonts/InterMedium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterMedium.woff2") format("woff2"),
    url("../fonts/InterMedium.woff") format("woff"),
    url("../fonts/InterMedium.ttf") format("truetype"),
    url("../fonts/InterMedium.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/InterSemiBold.eot");
  src: url("../fonts/InterSemiBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterSemiBold.woff2") format("woff2"),
    url("../fonts/InterSemiBold.woff") format("woff"),
    url("../fonts/InterSemiBold.ttf") format("truetype"),
    url("../fonts/InterSemiBold.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/InterBold.eot");
  src: url("../fonts/InterBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterBold.woff2") format("woff2"),
    url("../fonts/InterBold.woff") format("woff"),
    url("../fonts/InterBold.ttf") format("truetype"),
    url("../fonts/InterBold.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/InterExtraBold.eot");
  src: url("../fonts/InterExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterExtraBold.woff2") format("woff2"),
    url("../fonts/InterExtraBold.woff") format("woff"),
    url("../fonts/InterExtraBold.ttf") format("truetype"),
    url("../fonts/InterExtraBold.svg") format("svg");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/InterBlack.eot");
  src: url("../fonts/InterBlack.eot?#iefix") format("embedded-opentype"),
    url("../fonts/InterBlack.woff2") format("woff2"),
    url("../fonts/InterBlack.woff") format("woff"),
    url("../fonts/InterBlack.ttf") format("truetype"),
    url("../fonts/InterBlack.svg") format("svg");
} /*# sourceMappingURL=fonts.css.map */
/* Inter end */

@font-face {
  font-family: "icomoon-social";
  src: url("../fonts/social-network/icomoon-social.eot?i30xlb");
  src: url("../fonts/social-network/icomoon-social.eot?i30xlb#iefix")
      format("embedded-opentype"),
    url("../fonts/social-network/icomoon-social.ttf?i30xlb") format("truetype"),
    url("../fonts/social-network/icomoon-social.woff?i30xlb") format("woff"),
    url("../fonts/social-network/icomoon-social.svg?i30xlb#icomoon-social")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^="icon-social-"],
[class*="icon-social-"] {
  font-family: "icomoon-social" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-social-google:before {
  content: "\ea88";
}
.icon-social-google-plus:before {
  content: "\ea8b";
}
.icon-social-facebook:before {
  content: "\ea90";
}
.icon-social-instagram:before {
  content: "\ea92";
}
.icon-social-twitter:before {
  content: "\ea96";
}
.icon-social-rss:before {
  content: "\ea9b";
}
.icon-social-youtube:before {
  content: "\ea9d";
}
.icon-social-vimeo:before {
  content: "\eaa0";
}
.icon-social-flickr:before {
  content: "\eaa3";
}
.icon-social-dribbble:before {
  content: "\eaa7";
}
.icon-social-behance:before {
  content: "\eaa8";
}
.icon-social-dropbox:before {
  content: "\eaae";
}
.icon-social-github:before {
  content: "\eab0";
}
.icon-social-blogger:before {
  content: "\eab7";
}
.icon-social-tumblr:before {
  content: "\eab9";
}
.icon-social-yahoo:before {
  content: "\eabb";
}
.icon-social-skype:before {
  content: "\eac5";
}
.icon-social-linkedin:before {
  content: "\eaca";
}
.icon-social-lastfm:before {
  content: "\eacb";
}
.icon-social-stackoverflow:before {
  content: "\ead0";
}
.icon-social-pinterest:before {
  content: "\ead1";
}
.icon-social-foursquare:before {
  content: "\ead6";
}
.icon-social-yelp:before {
  content: "\ead7";
}

.icomoon-free--appleinc {
  display: inline-block;
  width: 1em;
  height: 1em;
  --svg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%23000' d='M12.367 8.501c-.02-2.026 1.652-2.998 1.727-3.046c-.94-1.375-2.404-1.564-2.926-1.585c-1.246-.126-2.431.734-3.064.734c-.631 0-1.607-.715-2.64-.696c-1.358.02-2.61.79-3.31 2.006c-1.411 2.448-.361 6.076 1.014 8.061c.672.972 1.473 2.064 2.525 2.025c1.013-.04 1.396-.656 2.621-.656s1.569.656 2.641.635c1.09-.02 1.781-.991 2.448-1.966c.772-1.128 1.089-2.219 1.108-2.275c-.024-.011-2.126-.816-2.147-3.236zm-2.014-5.946c.558-.677.935-1.617.832-2.555c-.804.033-1.779.536-2.356 1.212c-.518.6-.971 1.557-.85 2.476c.898.07 1.815-.456 2.373-1.132z'/%3E%3C/svg%3E");
  background-color: currentColor;
  -webkit-mask-image: var(--svg);
  mask-image: var(--svg);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
}
