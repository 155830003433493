@media (min-width: 768px) {
  .sidebar.collapse {
    display: block;
  }
  .main-content::after {
    content: "";
    position: absolute;
    background-color: var(--light);
    bottom: 0;
    top: 35%;
    width: 90%;
    right: 0;
    z-index: -1;
  }
  .main-wrapper {
    padding-top: 2rem;
  }
}
@media (max-width: 991.98px) {
  .logo-block {
    /* width: 120px!important; */
    width: 150px !important;
  }
  .left-sidebar {
    width: auto !important;
    padding: 0 !important;
  }
  .main-content {
    width: 100% !important;
  }
  #sidebarNavigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 300;
    height: 100vh;
    overflow: auto;
    /* background-color: rgba(1, 163, 255, 0.95); */
    background-color: rgba(0, 0, 0, 0.95);
    width: 80% !important;
    /* max-width: !important; */
    box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.085);
    padding: 1rem;
    display: block;
    visibility: hidden;
    transform: translate(-100%, 0);
    -webkit-transform: translate(-100%, 0);
    transition: all 0.25s ease-in-out 0s;
    -webkit-transition: all 0.25s ease-in-out 0s;
    -moz-transition: all 0.25s ease-in-out 0s;
    -ms-transition: all 0.25s ease-in-out 0s;
    -o-transition: all 0.25s ease-in-out 0s;
  }
  #sidebarNavigation.show {
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    visibility: visible;
  }
  #sidebarNavigation .card {
    box-shadow: none !important;
  }
  .leftbar-nav .navbar-nav .nav-link {
    color: var(--white);
  }
  .leftbar-nav .navbar-nav a.nav-link.active {
    border-radius: 0.25rem;
  }
  /*
  .leftbar-nav .navbar-nav a.nav-link.expanded {
    color: var(--primary);
    background-color: transparent;
  }

  .leftbar-nav .navbar-nav .nav-link:hover,
  .leftbar-nav .navbar-nav .nav-link.active {
    color: var(--primary);
    background-color: transparent;
  }

  .leftbar-nav .navbar-nav ul.submenu {
    background: rgba(255, 255, 255, 0.25);
  } */
  .leftbar-nav .navbar-nav ul.submenu li:last-child a.nav-link {
    border-radius: 0.25rem;
  }
  .leftbar-nav .navbar-nav a.nav-link.expanded {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  /* .leftbar-nav .navbar-nav ul.submenu a.nav-link {
    color: var(--white);
  }
  .leftbar-nav .navbar-nav .submenu a.nav-link {
    background-color: rgba(255, 255, 255, 0.25);
  } */
  .input-group-text {
    padding: 0.075rem 0.75rem;
  }
  .user-info-actions .user-name {
    color: #fff !important;
  }
  #sidebarNavigation .navbar-toggler {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 10;
    display: inline-block;
  }
  .user-info {
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }
}
@media (min-width: 1200px) {
  .main-wrapper {
    min-height: calc(100vh - 260px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media (min-width: 768px) and (max-width: 992px) {
  .lead {
    font-size: 16px;
  } /*
  h1,
  .h1 {
    font-size: 28px;
  }
  h2,
  .h2 {
    font-size: 24px;
  }
  h3,
  .h3 {
    font-size: 20px;
  }
  h4,
  .h4 {
    font-size: 18px;
  }
  h5,
  .h5 {
    font-size: 16px;
  }
  h6,
  .h6 {
    font-size: 14px;
  }*/
  .header .navbar-brand {
    max-width: 150px;
  }
  .profile-picture {
    width: 160px;
    height: 160px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
}
@media only screen and (min-width: 1025px) and (max-width: 1360px) {
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .earning-media {
    flex-direction: column;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1366px) {
}
@media (min-width: 992px) and (max-width: 1199px) {
}
/* @media (max-width: 767.98px) {
  .table-stacked table thead {
    display: none;
  }
  .table-stacked table tr td {
    display: block;
    position: relative;
    padding: 0.25rem 0.75rem 0.25rem 30%;
    border: 0;
  }
  .table-stacked table tr td:before {
    position: absolute;
    left: 0;
    content: attr(data-title);
    color: var(--dark);
  }
  .table-stacked table tr td:last-child {
    border-bottom: 2px solid #f8f9fa;
  }
} */
@media (max-width: 767px) {
  /* html {
    font-size: 12px;
  } */
  body {
    /* padding-top: 55px; */
    /* padding-top: 65px; */
  }
  body.login-body {
    padding-top: 0;
    background: #fff;
  }
  /*
  h1,
  .h1 {
    font-size: 24px;
  }
  h2,
  .h2 {
    font-size: 20px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
  h4,
  .h4 {
    font-size: 16px;
  }
  h5,
  .h5 {
    font-size: 14px;
  }
  h6,
  .h6 {
    font-size: 12px;
  }*/
  .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    height: 55px;
    background: #fff;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15) !important;
  }
  .btn-panels .btn {
    padding: 0.75rem;
  }
}
@media (max-width: 575.98px) {
  .product-action-tabs .nav {
    flex-direction: column !important;
    width: 100%;
  }
  .tab-left .nav a.nav-link {
    border-right: 1px solid #d2d2d2;
    width: 100%;
  }
  .tab-left .nav a.nav-link:last-child {
    border-radius: 0;
  }
  .tab-left .nav a.nav-link.active {
    order: 50;
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .tab-left .nav a.nav-link.active::after {
    content: "";
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -2px;
    top: auto;
    right: 0;
  }
  .tab-right {
    border: 1px solid #9e9e9e;
    border-radius: 0;
  }
}
@media (max-width: 375px) {
}
@media (min-width: 590px) {
}
