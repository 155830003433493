body {
  /* background: url(../images/background/main-bg.png) repeat 0px 0px; */
  background: #f5f5f5;
}
.logo-block {
  max-width: 300px;
}
.login-logo {
  max-width: 300px;
  margin: 0 auto;
}
.left-sidebar {
  /*	max-width:300px;
	width:300px;*/
  width: clamp(200px, 30%, 300px) !important;
}
/* .text-xs {
  font-size: 0.75rem;
}
.text-sm {
  font-size: 0.875rem;
}
.text-base {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.125rem;
}
.text-xl {
  font-size: 1.25rem;
} */
.text-xs{font-size:.75rem}
.text-sm{font-size:.875rem}
.text-base{font-size:clamp(0.875rem, 0.7188rem + 0.5vw, 1rem)}
.text-md{font-size:clamp(1rem, 0.5625rem + 1vw, 1.125rem)}
.text-lg{font-size:clamp(1.05rem, -0.1034rem + 2.406vw, 1.375rem)}
.text-xl{font-size:clamp(1.15rem, -0.1917rem + 3.0075vw, 1.563rem)}
.text-2xl{font-size:clamp(1.30rem, -0.6626rem + 4.5113vw, 2.25rem)}
.text-3xl{font-size:clamp(1.45rem, -1.7293rem + 7.5188vw, 2.5rem)}
.text-4xl{font-size:clamp(1.55rem, -4.4878rem + 13.5338vw, 3.125rem)}
.text-5xl{font-size:clamp(2rem, -1.3252rem + 9.0226vw, 4.375rem)}
.text-6xl{font-size:clamp(3.5rem, 0.15rem + 7vw, 4.75rem)}
.form-control-md {
  height: calc(2.5em + 0.75rem + 2px);
}
.btn-md {
  padding: 0.875rem 1.75rem;
}
.btn-social {
  text-align: center;
  font-size: 0.8rem;
  color: #fff;
  font-weight: 700;
  letter-spacing: 0.05rem;
  padding: 0.875rem 1.75rem;
}
.btn-social > i {
  margin-right: 0.5rem;
}
.btn-social:hover {
  opacity: 0.75;
  text-decoration: none;
  color: #fff;
}
.btn-facebook {
  background-color: #3b5998;
}
.btn-google-plus {
  background-color: #dd4b39;
}
.btn-apple {
  background-color: #6c757d;
}
.user-info {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.user-image-thumb {
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.user-image-thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.btn-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
}
#sidebar.navbar-expand-md .navbar-nav {
  -ms-flex-direction: column;
  flex-direction: column;
}
.leftbar-nav .navbar-nav .nav-item {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}
.leftbar-nav .navbar-nav .nav-link {
  color: var(--dark);
  /* background-color: rgba(255, 255, 255, 0.2); */
  background-color: transparent;
  border-radius: 0.45rem;
  padding: 0.75rem 0.8rem 0.75rem 0.8rem;
  /* padding: 0.75rem 1.25rem; */
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.leftbar-nav .navbar-nav .nav-link span,
.leftbar-nav .navbar-nav .nav-link .routeIcon {
  margin-right: 1rem;
}
.leftbar-nav .navbar-nav .nav-link:hover,
.leftbar-nav .navbar-nav .nav-link.active {
  color: var(--dark);
  /* background-color: rgba(255, 255, 255, 1); */
  background-color: #fff8eb;
}

.header .navbar-toggler {
  /* padding: 0.55rem 0.75rem; */
  border-color: var(--dark);
  background-color: transparent;
}
.header .navbar-toggler:focus {
  box-shadow: none;
}
.header .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 0, 0, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.header .navbar-toggler:hover .navbar-toggler-icon,
.header .navbar-toggler:active .navbar-toggler-icon,
.header .navbar-toggler:focus .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28 255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.main-content {
  position: relative;
  z-index: 2;
  width: clamp((100vw - 200px), (100vw - 70%), (100vw - 300px)) !important;
}

.main-content .card {
  border-radius: 1.125rem;
  box-shadow: unset !important;
}

.main-content .card .card-header:first-child {
  border-radius: calc(1.125rem - 1px) calc(1.125rem - 1px) 0 0;
}

.card-title {
  border-bottom: 1px solid #d2d5dd;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.footer .footer-wrap {
  border-top: 1px solid #d2d5dd;
  padding-top: 2rem;
}
@media screen and (min-width: 991.98px) {
  .sidebar-left {
    background: #fff;
    border-radius: 1.125rem;
  }
}

/*
----------------------------------------------------------------
	LOGIN
----------------------------------------------------------------
*/
.login-wrapper {
  min-height: 100vh;
}
.login-tabs {
  border: 0;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
  min-height: 50px;
}
.login-tabs .nav-item {
  margin-right: 0.5rem;
}
.login-tabs.nav-tabs .nav-link {
  border: 0;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 0.5rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--dark);
  font-size: 0.925rem;
}
.nav-tabs.login-tabs .nav-link.active,
.nav-tabs.login-tabs .active .nav-link {
  color: var(--primary);
  background-color: #fff;
  padding: 1rem 1.5rem 0.5rem;
  font-size: 1.125rem;
  font-weight: 500;
}
.login-card {
  border-radius: 0 0.75rem 0.75rem 0.75rem;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
}
.iti {
  width: 100%;
}

.invalid-feedback-bock {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.page-loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-loading .mat-spinner {
  width: 50px !important;
  height: 50px !important;
}

.page-loading .mat-spinner svg {
  width: 50px !important;
  height: 50px !important;
}
.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--primary) !important;
}

.mat-field-sm.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0;
}
.mat-field-sm.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.34375em;
}
.mat-field-sm.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-field-sm.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-1.205em) scale(0.75);
}
/* .mat-field-sm.mat-form-field-appearance-outline .mat-form-field-wrapper{
	padding-bottom: 0.644em;
} */
.mat-field-sm.mat-form-field-appearance-outline
  .mat-form-field-subscript-wrapper {
  padding: 0;
}

.mat-field-sm.mat-form-field-appearance-outline
  .mat-form-field-subscript-wrapper {
  margin-top: 0.367em;
}
.mat-field-sm.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 0;
}
.datepicker.mat-field-sm.mat-form-field-appearance-outline
  .mat-form-field-suffix {
  top: 0.5em;
}
.form-group .mat-error {
  font-size: 75%;
}

.leftbar-nav .navbar-nav a.nav-link.expanded {
  color: var(--dark);
  /* background-color: var(--white); */
  background-color: #e3ded3;
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  border-radius: 0.25rem;
}

.leftbar-nav .navbar-nav a.nav-link.active {
  border-bottom-left-radius: 0.45rem;
  border-bottom-right-radius: 0.45rem;
}

.leftbar-nav .navbar-nav ul.submenu {
  padding-left: 0;
  background: var(--light);
  border-radius: 0 0 0.45rem 0.45rem;
}
.leftbar-nav .navbar-nav a.nav-link .right-icon {
  position: absolute;
  right: 0;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.leftbar-nav .navbar-nav .submenu a.nav-link {
  color: var(--dark);
  background-color: #fff8eb;
  border-radius: 0;
}
.leftbar-nav .navbar-nav .submenu a.nav-link .mat-icon {
  font-size: 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.25);
}
.leftbar-nav .navbar-nav ul.submenu li.nav-item {
  margin-bottom: 0;
}
.leftbar-nav .navbar-nav ul.submenu a.nav-link {
  color: var(--dark);
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  padding: 0.55rem 1.25rem;
}

.leftbar-nav .navbar-nav ul.submenu a.nav-link:hover,
.leftbar-nav .navbar-nav ul.submenu a.nav-link:focus,
.leftbar-nav .navbar-nav ul.submenu a.nav-link.active {
  color: var(--primary);
}

.leftbar-nav .navbar-nav ul.submenu li:last-child a.nav-link {
  border-bottom: none;
  border-bottom-left-radius: 0.45rem;
  border-bottom-right-radius: 0.45rem;
}

.leftbar-nav .navbar-nav .mat-list-item-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.card-statistics-header {
  border: 0;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.75rem 0.5rem;
}
.statistics-date .material-icons {
  font-size: 1.125rem;
}
.ststc_date {
  font-size: 0.65rem;
}
.statistics-left h6 {
  font-size: 0.85rem;
  font-weight: 600;
}
.statistics-left h6 sup {
  font-size: 0.5rem;
}
.statistics-para {
  display: block;
  font-size: 0.65rem;
  opacity: 0.5;
  color: #000;
  font-weight: 900;
}
.statistics-lists .list-group-item {
  padding: 0;
  font-size: 0.65rem;
  background-color: transparent;
  font-weight: 900;
}
.statistics-lists .list-group-item span {
  color: #000;
  opacity: 0.5;
}
.statistics-lists .list-group-item .badge {
  font-size: 0.7rem;
  padding: 0.5rem 0;
  opacity: 1;
  color: #fff;
}
.floating-badge {
  position: absolute;
  top: 5px;
  right: 5px;
}
.product-container .product-img-wrap {
  overflow: hidden;
  min-height: 250px;
  max-height: 250px;
  max-width: 100%;
  border-top-left-radius: 1.125rem;
  border-top-right-radius: 1.125rem;
}
.product-container .product-img-wrap img {
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  max-height: 250px;
  border-top-left-radius: 1.125rem;
  border-top-right-radius: 1.125rem;
}
.product-container .product-title {
  font-size: 0.95rem;
}
.product-container .product-title h6 {
  margin-bottom: 0;
}
.button-container a.mat-button-base {
  padding: 0 5px;
  min-width: auto;
  line-height: 30px;
  margin-right: 5px;
  margin-bottom: 0.25rem;
}

.button-container a.mat-button-base .material-icons {
  font-size: 20px;
}
.btn-circle {
  border-radius: 50%;
}
.product-action-dropdown li > a {
  text-decoration: none;
  font-size: 0.875rem;
  color: var(--body);
  display: block;
}
.product-action-dropdown a:hover {
  color: var(--dark);
}
.btn-orange {
  color: #fff;
  background-color: var(--orange);
  border-color: var(--orange);
}
.btn-orange:hover {
  color: #fff;
  background-color: #f28529;
  border-color: #f28529;
}

.autocomplate-select {
  position: relative;
}
.autocomplate-select label {
  position: absolute;
  top: -0.6rem;
  left: 0.4rem;
  z-index: 9;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  background: #fff;
  padding: 0 6px;
}
.custom-file-upload-wrap {
  border: 2px dashed #888;
  min-height: 250px;
  position: relative;
  cursor: pointer;
  background-position: center center;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 0.75rem;
}
.custom-file-upload-wrap .mat-icon {
  transform: scale(2);
  color: var(--gray);
}

.secondary-images-container .image-new {
  min-height: 100px;
  border: 2px dashed var(--gray);
  margin-bottom: 15px;
  cursor: pointer;
  position: relative;
  padding: 0.5rem;
}
.secondary-images-container label.image-new .mat-icon {
  color: var(--gray);
}

.tab-left .nav {
  background: #f0f0f0;
  border-right: 0;
  border-radius: 0.25rem 0 0 0.25rem;
}

.tab-left .nav a.nav-link {
  color: #9e9e9e;
  border: 1px solid #d2d2d2;
  position: relative;
  z-index: 10;
  border-bottom-color: transparent;
  border-right: 0;
}

.tab-left .nav a.nav-link:first-child {
  border-radius: 0.25rem 0 0 0;
}
.tab-left .nav a.nav-link:last-child {
  border-radius: 0 0 0 0.25rem;
  border-bottom: 1px solid #d2d2d2;
}

.tab-left .nav a.nav-link.active {
  background: #fff;
  color: var(--primary);
  border-color: #9e9e9e !important;
}

.tab-left .nav a.nav-link.active::after {
  content: "";
  background-color: #fff;
  height: 100%;
  width: 5px;
  position: absolute;
  right: -3px;
  top: 0px;
  z-index: 1;
}

.tab-right {
  border: 1px solid #9e9e9e;
  border-radius: 0 0.25rem 0.25rem 0;
}

.tab-right .tab-content {
  padding: 20px;
}

.card-title .nav-link {
  font-size: 1rem;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.aerial_view_wrap .card-driver-lists {
  cursor: pointer;
}

.earning-container .media .mat-icon {
  height: 50px;
  width: 50px;
  font-size: 50px;
}

ng-select.ng-invalid.ng-touched .ng-select-container::after {
  border-color: #f44336;
  border-width: 2px;
}

ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder,
ng-select.ng-invalid.ng-touched
  .ng-select-container
  .ng-arrow-wrapper
  .ng-arrow {
  color: #f44336;
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  width: 93%;
  padding: 0 10px;
}

.ng-select.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  background-color: var(--primary) !important;
}
.ng-select .ng-clear-wrapper {
  z-index: 2;
}
.ng-select .ng-select-container.ng-appearance-outline {
  min-height: 51.5px !important;
}

/*  NEW CSS 08-10-2020
-------------------------------  */

.table thead th {
  font-weight: 600;
}
.table-condensed thead th {
  border-top: 0;
  border-bottom-width: 1px;
}
/*
-------------------------------
    IMG THUMBNAILS
-------------------------------
*/
.img-thumb-xs {
  width: 50px;
}
.img-thumb-sm {
  width: 80px;
}
.img-thumb-md {
  width: 120px;
}
.img-thumb-lg {
  width: 160px;
}
.img-thumb-xl {
  width: 200px;
}
.card-driver .card-header {
  position: relative;
  z-index: 2;
}
.card-driver .card-header:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: -1;
}
.driver-profile-picture {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.badge-active {
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 0.25rem 0.5rem;
}
.custom_nav_tabs {
  border: 0;
  background-color: var(--light);
}
.custom_nav_tabs.nav-tabs .nav-link {
  border: 0;
  border-bottom: 3px solid transparent;
  background-color: transparent;
  padding: 1rem 1.5rem;
  color: var(--dark);
  font-size: 1.125rem;
}
.custom_nav_tabs.nav-tabs .nav-link.active {
  border-color: var(--primary);
}

.primary-img-fluid {
  max-width: 100%;
  object-fit: cover;
  max-height: 250px;
}
.secondary-img-fluid {
  max-width: 100%;
  object-fit: cover;
  max-height: 80px;
}

.secondary-images-container .image-new .remove-secondary-image {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-images-container .image-new:hover .remove-secondary-image {
  opacity: 1;
  visibility: visible;
}

.product-badges {
  color: #fff;
  list-style: none;
}
.product-badges li {
  margin: 0px 3px 4px;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  background: var(--gray);
}

.product-badges li a {
  color: var(--light);
  padding: 0 0px 0 5px;
  font-weight: bold;
  text-decoration: none;
  opacity: 0.6;
}
.product-badges li a:hover {
  opacity: 1;
}

.ng-select.ng-select-disabled .ng-select-container::after {
  background-image: none !important;
}

.custom-time .btn.btn-link {
  padding: 0.5rem 0.85rem;
}
.custom-time .btn.btn-default {
  padding: 0.5rem 0.85rem;
  background: var(--light);
}

.custom-time .was-validated .form-control:invalid,
.custom-time .form-control.is-invalid {
  background: none;
  padding-right: 0.55rem;
}

.doc-uploded {
  position: relative;
  padding: 0 !important;
}
.overlay {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.75;
  display: block;
}
.doc-uploded img {
  width: 100%;
}
.overlay-content {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  color: #fff;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-flow: column;
  font-size: 0.875rem;
  font-weight: 500;
}

upload-wrap {
  padding: 2rem 1rem;
  border: 1px solid var(--light);
  position: relative;
}

.custom-doc-upload-wrap {
  padding: 2rem 1rem;
  border: 1px solid var(--gray);
  position: relative;
}

.custom-doc-upload-wrap input[type="file"] {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}

.custom-doc-upload-wrap label {
  display: inline-block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  font-size: 0.85rem;
  font-family: var(--font-montserrat);
  color: var(--dark);
}

.driver-profile-pic-box {
  height: 125px;
}

.driver-profile-pic-box .primary-img-fluid {
  max-height: 125px;
}

.attributeShow {
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
}

.attributeShow img {
  object-fit: cover;
  height: 40px;
  width: 40px;
}

.coupon-code-container {
  display: inline-block;
  padding: 3px;
  background: var(--red);
  color: #fff;
  border-radius: 5px;
  min-width: 50%;
  text-align: center;
}

.coupon-code-content {
  border: 1px dashed #fff;
  border-radius: 2px;
  padding: 5px 5px;
  font-size: 80%;
  font-weight: bold;
}

.discount-container::before {
  content: "";
  height: 36px;
  width: 30px;
  background: #fff;
  border-bottom-right-radius: 100%;
  position: absolute;
  left: -15px;
  border-top-right-radius: 100%;
}

.matrial-icon-100 {
  width: 100px !important;
  height: 100px !important;
}

.rating.color-warning .star-container .star svg {
  fill: var(--warning) !important;
}
.rating.color-warning .star-container .star svg.star-empty {
  fill: var(--gray-dark) !important;
}
.bg-teal {
  background-color: var(--teal) !important;
}

.custom-file-label {
  white-space: nowrap;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.product-ratings .rating {
  justify-content: left;
}

.product-ratings .star-container {
  margin-left: 0;
}

.product-ratings .star.medium,
.rating.medium .star {
  width: 15px;
  height: 15px;
}

.attribute {
  display: inline-block;
  margin-right: 5px;
  border: 0;
  padding: 0;
  position: relative;
}

.attribute:focus {
  outline: none;
}

.attibute-color {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.attribute-lists {
  display: flex;
}
.attribute span {
  display: inline-block;
  width: 100%;
  height: 100%;
  /* border-radius: 50%; */
  overflow: hidden;
}
.attibute-color.active span::after {
  content: "\f00c";
  position: absolute;
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #fff;
}

.attribute span i {
  color: #fff;
}

.attibute-lable {
  text-align: center;
  border: 1px solid var(--light);
  border-radius: 5px;
  padding: 3px 10px;
}

.attibute-image {
  height: 50px;
  width: 50px;
  border: 1px solid var(--light);
  border-radius: 5px;
}

.attibute-lable.active,
.attibute-image.active {
  border: 2px solid var(--primary);
}
.document-upload {
  position: relative;
}
.document-upload .image-new {
  border: 1px solid #ddd;
  height: 200px;
  cursor: pointer;
  text-decoration: none;
}
.for-variation .image-new {
  height: 100px;
}

.document-upload .remove-file {
  position: absolute;
  top: 0;
  right: 3px;
  cursor: pointer;
}

.datepicker.mat-field-sm.mat-form-field-appearance-outline
  .mat-form-field-suffix {
  top: 0.55rem;
}
.product-container .card-footer:last-child {
  border-bottom-left-radius: 1.125rem;
  border-bottom-right-radius: 1.125rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--primary);
}
.nav-pills .nav-link,
.nav-pills > .nav-link {
  color: var(--gray);
}
.avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  background-color: #fdcdbb;
  margin-bottom: 1rem;
  overflow: hidden;
}
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.avatar.avatar-circle {
  border-radius: 50%;
}
.avatar-xs {
  width: 20px;
  height: 20px;
}
.avatar-sm {
  width: 40px;
  height: 40px;
}
.avatar-md {
  width: 60px;
  height: 60px;
}
.avatar-lg {
  width: 80px;
  height: 80px;
}
.avatar-xl {
  width: 100px;
  height: 100px;
}
.table .rating {
  justify-content: flex-start;
}
.prodcut-thumbnail {
  display: inline-block;
  min-width: 50px;
}

.two-line-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}

.blank-divider {
  border-bottom: 2px dashed #8d8d8d;
  width: 100%;
}
.summary-hr {
  border-width: 2px;
  border-color: #8d8d8d;
}

.mat-mdc-form-field-subscript-wrapper{
  margin-bottom: 10px;
}
.action-btn .btn ,.action-btn  .mdc-button{
  min-width:36px;
  text-decoration: none;
  padding: .5rem;
}
.action-btn .material-icons{
  font-size: .95rem;
  margin: 0 !important;
}
.action-btn .btn + .btn,.action-btn  .mdc-button + .mdc-button{
  margin-left: .25rem;
}
.mat-mdc-radio-button .mdc-form-field > label{
  margin-bottom: 0;
}
.badge {
  font-weight: 500;
}