// Custom Theming for Angular Material
// @use '~@angular/material' as mat;
@use "@angular/material" as mat;
// For more information: https://material.angular.io/guide/theming
// @import "~@angular/material/theming";
// @import "@angular/material/theming";
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import "@ng-select/ng-select/themes/material.theme.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$custom-theme_palette: (
  50: #fef4e8,
  100: #fee4c5,
  200: #fdd39e,
  300: #fcc177,
  400: #fbb359,
  500: #faa63c,
  600: #f99e36,
  700: #f9952e,
  800: #f88b27,
  900: #f67b1a,
  A100: #ffffff,
  A200: #fff9f6,
  A400: #ffdbc3,
  A700: #ffcca9,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $furni-primary: mat-palette($mat-indigo);
// $furni-primary: mat-palette($mat-blue, 500);
// $furni-primary: mat-palette($mat-teal, A700);
$furni-primary: mat.define-palette($custom-theme_palette, 500);
$furni-accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$furni-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
// $furni-theme: mat.define-light-theme(
//   $furni-primary,
//   $furni-accent,
//   $furni-warn
// );

// Define the color configuration map
$furni-color-config: (
  color: (
    primary: $furni-primary,
    accent: $furni-accent,
    warn: $furni-warn,
  ),
  typography: mat.define-typography-config(),
  // Default typography
  density: 0,
  // Default density
);

// Create the theme object using the color configuration map
$furni-theme: mat.define-light-theme($furni-color-config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($furni-theme);
